import(/* webpackMode: "eager", webpackExports: ["ReduxProvider","NextAuthSessionProvider"] */ "/codebuild/output/src2775885192/src/alien-wardrobe/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2775885192/src/alien-wardrobe/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2775885192/src/alien-wardrobe/components/layout/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src2775885192/src/alien-wardrobe/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2775885192/src/alien-wardrobe/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Cormorant_Garamond\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"700\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"cormorantGaramond\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2775885192/src/alien-wardrobe/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2775885192/src/alien-wardrobe/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"style\":\"normal\"}],\"variableName\":\"outfit\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2775885192/src/alien-wardrobe/styles/globals.scss");
