import { CouponType } from "@prisma/client"
import axios from "axios"
import bcrypt from "bcryptjs"
import { clsx, type ClassValue } from "clsx"
import { toast } from "sonner"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export async function verifyPassword(
  inputPassword: string,
  storedPassword: string
) {
  return await bcrypt.compare(inputPassword, storedPassword)
}

export const initiateTransaction = async (
  amount: number,
  type: string,
  walletId: string,
  customerEmail: string
): Promise<boolean> => {
  const res = await initializeRazorpay()
  if (!res) {
    alert("Razorpay SDK Failed to load")
    return false
  }

  const { data } = await axios.post("/api/razorpay", { amount })

  return new Promise((resolve, reject) => {
    var options = {
      key: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID,
      name: "Alien Wardrobe",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Thank you for your test donation",
      image: "https://picsum.photos/seed/JljD8cVy/640/480",
      handler: async function (response: {
        razorpay_payment_id: any
        razorpay_order_id: any
        razorpay_signature: any
      }) {
        try {
          const transaction = await axios.post("/api/transaction", {
            type: type,
            amount: amount,
            walletId: walletId,
          })

          const payment = await axios.post("/api/payment", {
            rpReferenceId: response.razorpay_payment_id,
            rpPaymentId: response.razorpay_order_id,
            amount: data.amount,
            transactionId: transaction.data.data.id,
          })

          if (payment.status === 200 && transaction.status === 200) {
            const updateWallet = await axios.put(`/api/wallet/`, {
              amount: amount,
            })

            if (updateWallet.status === 200) {
              toast.success("Payment Successful! Thank you for your topup.")
              resolve(true)
            }
          } else {
            throw new Error("Payment process failed")
          }
        } catch (error) {
          console.error("Payment handler error: ", error)
          toast.error("Payment processing failed. Please try again.")
          resolve(false)
        }
      },
      prefill: {
        name: "Alien Wardrobe Admin",
        email: customerEmail,
      },
      modal: {
        ondismiss: async function () {
          toast.success("Transaction Cancelled")

          resolve(false)
        },
      },
    }

    const paymentObject = new (window as any).Razorpay(options)
    paymentObject.open()
  })
}

const initiatePayment = async (
  amount: any,
  orderId: string,
  customerEmail: string,
  customerPhone: string
): Promise<boolean> => {
  const res = await initializeRazorpay()
  if (!res) {
    alert("Razorpay SDK Failed to load")
    return false
  }

  const { data } = await axios.post("/api/razorpay", { amount })

  return new Promise((resolve, reject) => {
    var options = {
      key: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID,
      name: "Alien Wardrobe",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Thank you for your test donation",
      image: "https://picsum.photos/seed/JljD8cVy/640/480",
      handler: async function (response: {
        razorpay_payment_id: any
        razorpay_order_id: any
        razorpay_signature: any
      }) {
        try {
          const updateOrderStatus = await axios.put(`/api/order/${orderId}`, {
            status: "PAID",
          })
          const payment = await axios.post("/api/payment", {
            rpReferenceId: response.razorpay_payment_id,
            rpPaymentId: response.razorpay_order_id,
            amount: data.amount,
            orderId,
          })
          const clearCart = await axios.delete("/api/cart?clearAll=true")

          if (
            payment.status === 200 &&
            updateOrderStatus.status === 200 &&
            clearCart.status === 200
          ) {
            //toast.success("Payment Successful! Thank you for your order.")
            resolve(true)
          } else {
            throw new Error("Payment process failed")
          }
        } catch (error) {
          console.error("Payment handler error: ", error)
          toast.error("Payment processing failed. Please try again.")
          resolve(false)
        }
      },
      prefill: {
        name: "Alien Wardrobe Admin",
        email: customerEmail,
        contact: customerPhone,
      },
      modal: {
        ondismiss: async function () {
          const updateOrderStatus = await axios.put(`/api/order/${orderId}`, {
            status: "CANCELLED",
          })
          if (updateOrderStatus.status === 200) {
            toast.success("Order Cancelled")
          }
          resolve(false)
        },
      },
    }

    const paymentObject = new (window as any).Razorpay(options)
    paymentObject.open()
  })
}

const initializeRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script")
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

/**
 * Util function to validate coupon
 * @param coupon - existing coupon object
 * @param body - incoming request {code: "ABCD", value: 100}
 * @param session - user session
 * @returns void
 */
const validateCoupon = (coupon: any, value: any, session: any): void => {
  //   check order value
  if (value < coupon.miniumOrderValue || value > coupon.maximumOrderValue) {
    console.log("Order value exceed")
    throw new Error(
      `Order value must be ${coupon.miniumOrderValue}-${coupon.maximumOrderValue}`
    )
  }

  //   check token expiry
  const currentDate = new Date()
  console.log("currentDate :: ", currentDate)
  if (currentDate < coupon.startDate || currentDate > coupon.endDate) {
    console.log("Coupon has expired")
    throw new Error("Coupon has expired")
  }

  //   check maximum usage
  const usageCount = coupon.orders.filter(
    (order: any) => order.userId === session?.user?.id
  ).length
  console.log("Usage count :: ", usageCount)
  if (usageCount >= coupon.maximumUsage) {
    console.log("Coupon exceeds usage limit")
    throw new Error(
      "Sorry, this coupon code has exceeded the maximum allowed usage limit."
    )
  }

  console.log("Coupon is valid")
}

const getPriceDetails = (cartDetails: any) => {
  let subTotal = 0
  let discount = 0
  cartDetails?.cartItems?.map((item: any) => {
    subTotal = subTotal + item.quantity * item.productVariant.product.price
  })

  if (cartDetails?.coupon?.type === CouponType.FLAT) {
    discount = cartDetails?.coupon?.value
  }

  if (cartDetails?.coupon?.type === CouponType.PERCENTAGE) {
    discount = (cartDetails?.coupon.value / 100) * subTotal
  }

  return {
    subTotal: subTotal.toFixed(2),
    discount: discount.toFixed(2),
    total: (subTotal - discount).toFixed(2),
  }
}

const encodeSKU = (sku: string): string => {
  return sku.replace(/\//g, ":")
}

const decodeSKU = (encodedSKU: string): string => {
  return encodedSKU.replace(/:/g, "/")
}

export {
  initiatePayment,
  validateCoupon,
  getPriceDetails,
  encodeSKU,
  decodeSKU,
}
