import authConfig from "@/auth.config"
import { PrismaAdapter } from "@auth/prisma-adapter"
import axios from "axios"
import NextAuth from "next-auth"

import { getAccountByUserId, getUserById } from "@/lib/helpers"
import prisma from "@/lib/prisma"

export const {
  handlers: { GET, POST },
  auth,
  signIn,
  signOut,
} = NextAuth({
  pages: {
    signIn: "/login",
    error: "/auth/error",
  },
  events: {
    async linkAccount({ user }) {
      try {
        console.log("Link user account :: ", user)

        const userData = await prisma.user.upsert({
          where: { id: user.id },
          create: {
            id: user.id,
            email: user.email as string,
            emailVerified: new Date(),
          },
          update: { emailVerified: new Date() },
          include: { cart: true, wallet: true },
        })

        if (user.id && !userData.cart) {
          await prisma.cart.create({
            data: { userId: user.id },
          })
        }

        if (user.id && !userData.wallet) {
          await axios.post("/api/wallet")
        }
      } catch (error) {
        console.log("Error while linking user account to db :: ", error)
        throw error
      }
    },
  },
  callbacks: {
    async signIn({ account }) {
      try {
        console.log("Sign in user")
        // Allow OAuth without email verification
        if (account?.provider !== "credentials") return true

        // const existingUser = await getUserById(user.id as string)

        return true
      } catch (error) {
        console.log("Error inside sign in method :: ", error)
        return false
      }
    },
    async session({ token, session }) {
      try {
        console.log("user session")
        if (token.sub && session.user) {
          session.user.id = token.sub
        }

        if (session.user) {
          session.user.name = token.name
          session.user.email = token.email as string
          session.user.isOAuth = token.isOAuth as boolean
        }

        return session
      } catch (error) {
        console.log("Error inside session method :: ", error)
        throw error
      }
    },
    async jwt({ token }) {
      try {
        console.log("user jwt")
        if (!token.sub) return token

        const existingUser = await getUserById(token.sub)

        if (!existingUser) return token

        const existingAccount = await getAccountByUserId(existingUser.id)

        token.isOAuth = !!existingAccount
        token.name = existingUser.name
        token.email = existingUser.email
        return token
      } catch (error) {
        console.log("Error inside jwt method :: ", error)
        throw error
      }
    },
  },
  adapter: PrismaAdapter(prisma),
  session: { strategy: "jwt" },
  trustHost: true,
  ...authConfig,
})
